import Routing from "./routes/Routing"
import { Tooltip } from "@mui/material";
import { IoLogoWhatsapp } from "react-icons/io5";
import io from "socket.io-client"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { SET_CATEGORY_SUBCATEGORY, getCategories, getSubCategories } from "./store/features/slices/categorySlice";
import { LOGOUT, SET_TO_DEFAULT_RESPONSE, getConfiguration, getInitialData, getUserInfo } from "./store/features/slices/userSlice";
import Swal from "sweetalert2";
import { SET_PLATAFORM_SERVICE, getPlataforms, getServices } from "./store/features/slices/socialSlice";
import { getLast10Ventas } from "./store/features/slices/historySlice";
import SparklesBackground from "./components/home/SparklesBackground";
import { useTranslation } from 'react-i18next';

function App() {
  const dispatch = useDispatch();
  const { isMutation, userToken, adminToken, user, admin, configuration } = useSelector((state) => state.userReducer);
  const { t } = useTranslation();

  useEffect(() => {
    if (userToken || adminToken) {
      dispatch(getUserInfo(userToken ? user?.id : admin?.id))
    }
    dispatch(getInitialData())
    /*dispatch(getCategories())
    dispatch(getSubCategories())
    dispatch(getPlataforms())
    dispatch(getServices())*/
    //dispatch(getLast10Ventas())
  }, [dispatch])

  useEffect(() => {
    if (isMutation.sSuccess) {
      dispatch(SET_CATEGORY_SUBCATEGORY({ categories: isMutation.extra.categories, subcategories: isMutation.extra.subcategories }))
      dispatch(SET_PLATAFORM_SERVICE({ plataforms: isMutation.extra.plataforms, services: isMutation.extra.services }))
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
  }, [isMutation.sSuccess])

  const checkTokenExpiration = () => {
    const token = userToken ? localStorage.getItem('userToken') : localStorage.getItem('adminToken'); // O donde guardes tu token
    if (!token) return;

    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      let timerInterval;
      Swal.fire({
        title: "Cierre de sesión automático!",
        html: "Sesión expirada, la sesión se cerrará en <b></b> segundos.",
        timer: 10000,
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
          }, 500);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          dispatch(LOGOUT(userToken ? 'userToken' : 'adminToken'));
        }
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(checkTokenExpiration, 15000); // Verificar cada 15 segundo
    return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar el componente
  }, []);

  useEffect(() => {
    // if set via local storage previously
    // LIGHT DEFAULT
    if (localStorage.getItem('color-theme')) {
      if (localStorage.getItem('color-theme') === 'light') {
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
        localStorage.setItem('color-theme', 'light');
      } else {
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
        localStorage.setItem('color-theme', 'dark');
      }

      // if NOT set via local storage previously
    } else {
      if (document.documentElement.classList.contains('light')) {
        document.documentElement.classList.remove('light');
        localStorage.setItem('color-theme', 'dark');
      } else {
        document.documentElement.classList.add('light');
        localStorage.setItem('color-theme', 'light');
      }
    }

    // DARK DEFAULT
    /*if (localStorage.getItem('color-theme')) {
      if (localStorage.getItem('color-theme') === 'light') {
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
        localStorage.setItem('color-theme', 'light');
      } else {
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
        localStorage.setItem('color-theme', 'dark');
      }

      // if NOT set via local storage previously
    } else {
      if (document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('color-theme', 'light');
      } else {
        document.documentElement.classList.add('dark');
        localStorage.setItem('color-theme', 'dark');
      }
    }*/

  }, [])

  /*const enterFullscreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { 
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { 
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { 
      elem.msRequestFullscreen();
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/Mobi|Android/i.test(userAgent)) {
      document.addEventListener('click', enterFullscreen);
      return () => {
        document.removeEventListener('click', enterFullscreen);
      };
    } else if (/Tablet|iPad/i.test(userAgent)) {
      document.addEventListener('click', enterFullscreen);
      return () => {
        document.removeEventListener('click', enterFullscreen);
      };
    }
  }, []);*/


  return (
    <>
    <SparklesBackground />
      <Routing />
      <Tooltip title={t('btnwhatsapp')} arrow={true}>
        <a href={`https://wa.me/${configuration?.social?.whatsapp?.replace(/\s+/g, '').replace('+', '')}`} className="float flex items-center justify-center" target="_blank">
          <IoLogoWhatsapp />
        </a>
      </Tooltip>
    </>

  );
}
export default App;
