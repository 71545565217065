import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleLeft, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { FaAngleRight } from 'react-icons/fa6';


const SliderBanner = ({ }) => {
  const { configuration } = useSelector((state) => state.userReducer);

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style, position: '!relative', background: '!transparent', zIndex: 20 }}
      onClick={onClick} >
      <div className='rounded-full group absolute !bg-black/5 dark:!bg-white/5 hover:!bg-black/20 dark:hover:!bg-white/20 transition-all duration-500 p-2 flex items-center justify-center -top-4 right-7'>
          <FaAngleRight className='!z-50 !text-[20px] !text-black/30 dark:!text-white/30 group-hover:!text-black/60 dark:group-hover:!text-white/60 transition-all duration-500' />
        </div>
    </div>);
  };

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style, position: '!relative', background: '!transparent', zIndex: 20 }}
        onClick={onClick} >
        <div className='rounded-full group absolute !bg-black/5 dark:!bg-white/5 hover:!bg-black/20 dark:hover:!bg-white/20 transition-all duration-500 p-2 flex items-center justify-center -top-4 left-7'>
          <FaAngleLeft className='!z-50 !text-[20px] !text-black/30 dark:!text-white/30 group-hover:!text-black/60 dark:group-hover:!text-white/60 transition-all duration-500' />
        </div>
      </div>);
  };

  var settings = {
    centerMode: true,
    fade: true,
    dots: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  };

  return (
    <div className="w-fullmin-h-[259px] md:min-h-[479px] max-h-[479px] relative">
      <div className={`absolute -left-1 z-20 w-3/12 h-full bg-gradient-to-r from-white dark:from-black`}>

      </div>
      <div className={`absolute -right-1 z-20 w-3/12 h-full bg-gradient-to-l from-white dark:from-black`}>
        <div></div>
      </div>
      <Slider className="!relative !overflow-hidden" {...settings}>
        {
          configuration.sliders?.map((slider) => {
            return (
              <div className="relative min-h-[259px] md:min-h-[479px] max-h-[479px] overflow-hidden" key={slider._id}>
                <LazyLoadImage
                  className="w-full min-h-[259px] md:min-h-[479px] object-cover"
                  alt={`No image`}
                  effect="opacity"
                  src={`./images/logos/${slider.url}`}
                />
              </div>)
          })
        }
      </Slider>
    </div>
  );
};
export default SliderBanner;
