import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userService from "../services/userService";
import jwtDecode from "jwt-decode"
import { verifyToken, message } from "../../../utils/Funciones";
const userToken = localStorage.getItem('userToken')
const adminToken = localStorage.getItem('adminToken')

const initialState = {
    isMutation: { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {}, sloading: false, sSuccess: false },
    contador: { locked: 0, reseller: 0, vip: 0, total: 0, loading: false },
    userToken: verifyToken('userToken'),
    user: userToken ? jwtDecode(userToken) : null,
    adminToken: verifyToken('adminToken'),
    admin: adminToken ? jwtDecode(adminToken) : null,
    theme: localStorage.getItem('color-theme') ? localStorage.getItem('color-theme') : 'dark',
    userInfo: {},
    adminInfo: {},
    configuration: { sliders: [] },
    usersByPage: [],
    users: [],
    totalFilter: 0,
    total: 0,
    currentPage: 1,
    totalPages: 1,
    totalStoreValue: 0
};

export const login = createAsyncThunk(
    "user/login/login",
    async (formData, thunkAPI) => {
        try {
            return await userService.login(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const register = createAsyncThunk(
    "user/register/register",
    async (formData, thunkAPI) => {
        try {
            return await userService.register(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const subscribevip = createAsyncThunk(
    "user/subscribevip/subscribevip",
    async (formData, thunkAPI) => {
        try {
            return await userService.subscribevip(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const create = createAsyncThunk(
    "user/create",
    async (formData, thunkAPI) => {
        try {
            return await userService.create(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const get = createAsyncThunk(
    "user/get",
    async (token, thunkAPI) => {
        try {
            return await userService.get(token);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getInitialData = createAsyncThunk(
    "user/getInitialData",
    async (_, thunkAPI) => {
        try {
            return await userService.getInitialData();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getConfiguration = createAsyncThunk(
    "user/getConfiguration",
    async (_, thunkAPI) => {
        try {
            return await userService.getConfiguration();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getUserInfo = createAsyncThunk(
    "user/getUserInfo/userinfo",
    async (id, thunkAPI) => {
        try {
            return await userService.getUserInfo(id);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getById = createAsyncThunk(
    "user/getById",
    async (token, thunkAPI) => {
        try {
            return await userService.getById(token);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getByPage = createAsyncThunk(
    "user/getByPage/bypage",
    async (query, thunkAPI) => {
        try {
            return await userService.getByPage(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const contador = createAsyncThunk(
    "user/contador/contador",
    async (_, thunkAPI) => {
        try {
            return await userService.contador();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


export const update = createAsyncThunk(
    "user/update/updateuser",
    async (formData, thunkAPI) => {
        try {
            return await userService.update(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


export const updateVip = createAsyncThunk(
    "user/updateVip/updateuservip",
    async (formData, thunkAPI) => {
        try {
            return await userService.updateVip(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const forgotPasswordRequest = createAsyncThunk(
    "user/forgotPasswordRequest/forgotpasswordrequest",
    async (formData, thunkAPI) => {
        try {
            return await userService.forgotPasswordRequest(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateForgotPasswordRequest = createAsyncThunk(
    "user/updateForgotPasswordRequest/updateforgotpasswordrequest",
    async (formData, thunkAPI) => {
        try {
            return await userService.updateForgotPasswordRequest(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateProfileInfo = createAsyncThunk(
    "user/updateProfileInfo/updateprofileinfo",
    async (formData, thunkAPI) => {
        try {
            return await userService.updateProfileInfo(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateConfiguration = createAsyncThunk(
    "user/updateConfiguration/updateconfiguration",
    async (formData, thunkAPI) => {
        try {
            return await userService.updateConfiguration(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const lock = createAsyncThunk(
    "user/lock/lock",
    async (formData, thunkAPI) => {
        try {
            return await userService.lock(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const addCash = createAsyncThunk(
    "user/addCash/addcash",
    async (formData, thunkAPI) => {
        try {
            return await userService.addCash(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const del = createAsyncThunk(
    "user/del",
    async (id, thunkAPI) => {
        try {
            return await userService.del(id);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {}, sloading: false, sSuccess: false }
        },

        SET_THEME(state, action) {
            state.theme = action.payload
        },

        UPDATE_CONFIGURATION(state, action) {
            state.configuration = action.payload
        },

        UPDATE_USER_INFO(state, action) {
            state.userInfo._id = action.payload._id;
            state.userInfo.admin = action.payload.admin;
            state.userInfo.phonenumber = action.payload.phonenumber;
            state.userInfo.countrycode = action.payload.countrycode;
            state.userInfo.credits_purchased = action.payload.credits_purchased;
            state.userInfo.credits_used = action.payload.credits_used;
            state.userInfo.locked = action.payload.locked;
            state.userInfo.name = action.payload.name;
            state.userInfo.email = action.payload.email;
            state.userInfo.reseller = action.payload.reseller;
            state.userInfo.photo = action.payload.photo;
            state.userInfo.created_at = action.payload.created_at;
            state.userInfo.last_update = action.payload.last_update
        },
        SET_CASH_USER(state, action) {
            if (action.payload.type === 'INCREASE') {
                state.userInfo.credits_purchased += action.payload.amount
            } else if (action.payload.type === 'DECREASE') {
                state.userInfo.credits_used += action.payload.amount
            }
        },
        SET_ADMIN_TOKEN(state, action) {
            localStorage.setItem('adminToken', action.payload);
            state.adminToken = action.payload;
            state.admin = jwtDecode(action.payload);
        },
        SET_USER_TOKEN(state, action) {
            localStorage.setItem('userToken', action.payload);
            state.userToken = action.payload;
            state.user = jwtDecode(action.payload);
        },
        LOGOUT(state, { payload }) {
            localStorage.removeItem(payload)
            if (payload === 'adminToken') {
                state.adminToken = null;
                state.admin = null;
            } else if (payload === 'userToken') {
                state.userToken = null;
                state.user = null;
                state.userInfo = {}
            }
        }
    },
    extraReducers: (builder) => {
        builder
            // LOGIN
            .addCase(login.pending, (state) => {
                state.isMutation.sloading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                message(action.payload.message, 'success', 5)
                if (action.payload.admin && action.payload.adminlogin) {
                    state.adminToken = action.payload.token;
                    state.admin = jwtDecode(action.payload.token);
                    state.userInfo = action.payload.userInfo;
                    state.adminInfo = action.payload.userInfo;
                    localStorage.setItem('adminToken', action.payload.token);
                } else if (!action.payload.adminlogin || action.payload.admin) {
                    state.userToken = action.payload.token;
                    state.user = jwtDecode(action.payload.token);
                    state.userInfo = action.payload.userInfo
                    localStorage.setItem('userToken', action.payload.token);
                }
                state.isMutation.sloading = false;
            })
            .addCase(login.rejected, (state, action) => {
                state.isMutation.sloading = false;
                state.isMutation.extra.error = action.payload.error
                state.isMutation.error = true;
            })
            // REGISTER
            .addCase(register.pending, (state) => {
                state.isMutation.sloading = true;
            })
            .addCase(register.fulfilled, (state, action) => {
                message(action.payload.message, 'success', 5)
                if (action.payload.admin) {
                    state.adminToken = action.payload.token;
                    state.admin = jwtDecode(action.payload.token);
                    localStorage.setItem('adminToken', action.payload.token);
                } else {
                    state.userToken = action.payload.token;
                    state.user = jwtDecode(action.payload.token);
                    state.userInfo = action.payload.userInfo
                    localStorage.setItem('userToken', action.payload.token);
                }
                state.isMutation.sloading = false;
            })
            .addCase(register.rejected, (state, action) => {
                state.isMutation.sloading = false;
                state.isMutation.error = true;
            })

            // CREATE
            .addCase(create.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(create.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.success = true;
                state.isMutation.message = action.payload.message;
            })
            .addCase(create.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // SUBSCRIPTION VIP
            .addCase(subscribevip.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(subscribevip.fulfilled, (state, action) => {
                state.isMutation.success = true;
                state.isMutation.message = action.payload.message;
                state.configuration = action.payload.configuration;
                state.userInfo.credits_used += action.payload.amount;
                state.userInfo.vip = action.payload.uservip;
                state.isMutation.loading = false;
            })
            .addCase(subscribevip.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET INITIAL DATA
            .addCase(getInitialData.pending, (state) => {
                state.isMutation.sloading = true;
            })
            .addCase(getInitialData.fulfilled, (state, action) => {
                state.configuration = action.payload.configuration;
                state.isMutation.extra.categories = action.payload.categories;
                state.isMutation.extra.subcategories = action.payload.subcategories;
                state.isMutation.extra.plataforms = action.payload.plataforms;
                state.isMutation.extra.services = action.payload.services;
                state.isMutation.sSuccess = true;
                state.isMutation.sloading = false;
            })
            .addCase(getInitialData.rejected, (state, action) => {
                state.isMutation.sloading = false;
            })

            // GET
            .addCase(get.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(get.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.data = action.payload.data;
            })
            .addCase(get.rejected, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.message = action.payload;
            })

            // GET CONFIGURATION

            .addCase(getConfiguration.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getConfiguration.fulfilled, (state, action) => {
                state.configuration = action.payload.configuration
                state.isMutation.loading = false;
            })
            .addCase(getConfiguration.rejected, (state, action) => {
                state.isMutation.loading = true;
            })

            // GET USER INFO
            .addCase(getUserInfo.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getUserInfo.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                if (action.payload.user?.admin) {
                    state.adminInfo = action.payload.user;
                    state.userInfo = action.payload.user;
                } else {
                    state.userInfo = action.payload.user;
                }
            })
            .addCase(getUserInfo.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CONTADOR

            .addCase(contador.pending, (state) => {
                state.contador.loading = true
            })
            .addCase(contador.fulfilled, (state, action) => {
                state.contador.locked = action.payload.totaluserslocked;
                state.contador.reseller = action.payload.totalusersreseller;
                state.contador.vip = action.payload.totalusersvip;
                state.contador.total = action.payload.totalusers;
                state.contador.loading = false
            })
            .addCase(contador.rejected, (state, action) => {
                state.contador.loading = false
            })

            // GET_BYPAGE

            .addCase(getByPage.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getByPage.fulfilled, (state, action) => {
                state.usersByPage = action.payload.users;
                state.totalFilter = action.payload.totalusersFilter;
                state.total = action.payload.totalusers;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
                state.isMutation.loading = false;
            })
            .addCase(getByPage.rejected, (state, action) => {
                state.isMutation.loading = true;
            })

            // UPDATE
            .addCase(update.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(update.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.usersByPage.map((user) => {
                    if (user._id === action.payload.newData._id) {
                        user = action.payload.newData
                    }
                    return user
                })
                if (newState.filter((fil) => fil.reseller).length > state.usersByPage.filter((fil) => fil.reseller).length) {
                    state.contador.reseller += 1
                } else if (newState.filter((fil) => fil.reseller).length < state.usersByPage.filter((fil) => fil.reseller).length) {
                    state.contador.reseller -= 1
                }
                state.usersByPage = newState
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(update.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // UPDATE VIP
            .addCase(updateVip.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(updateVip.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.usersByPage.map((user) => {
                    if (user._id === action.payload.newData._id) {
                        user = action.payload.newData
                    }
                    return user
                })
                if (newState.filter((fil) => fil?.vip?.state === "ACTIVE").length > state.usersByPage.filter((fil) => fil?.vip?.state === "ACTIVE").length) {
                    state.contador.vip += 1
                } else if (newState.filter((fil) => fil?.vip?.state === "ACTIVE").length < state.usersByPage.filter((fil) => fil?.vip?.state === "ACTIVE").length) {
                    state.contador.vip -= 1
                }
                state.usersByPage = newState;
                state.isMutation.extra.uUserInfo = true;
                state.isMutation.extra.action = 'updateVip';
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(updateVip.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // FORGOT PASSWORD REQUEST
            .addCase(forgotPasswordRequest.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(forgotPasswordRequest.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(forgotPasswordRequest.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // UPDATE FORGOT PASSWORD REQUEST
            .addCase(updateForgotPasswordRequest.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(updateForgotPasswordRequest.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(updateForgotPasswordRequest.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // UPDATE PROFILE INFO
            .addCase(updateProfileInfo.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(updateProfileInfo.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                if (action.payload.type === 'name') {
                    state.userInfo.name = action.payload.newValue
                } else if (action.payload.type === 'email') {
                    state.userInfo.email = action.payload.newValue
                } else if (action.payload.type === 'phone') {
                    state.userInfo.countrycode = action.payload.newCountryCode
                    state.userInfo.phonenumber = action.payload.newValue
                } else if (action.payload.type === 'photo') {
                    state.userInfo.photo = action.payload.newValue
                }

                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(updateProfileInfo.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // UPDATE CONFIGURATION
            .addCase(updateConfiguration.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(updateConfiguration.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.isMutation.extra.newConfiguration = action.payload.configuration
                state.configuration = action.payload.configuration
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(updateConfiguration.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // LOCK USER
            .addCase(lock.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(lock.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.usersByPage.map((user) => {
                    if (user._id === action.payload.newData._id) {
                        return {
                            ...user,
                            locked: action.payload.newData.locked,
                        }
                    }
                    return user
                })
                if (action.payload.newData.locked) {
                    state.contador.locked += 1
                } else {
                    state.contador.locked -= 1
                }
                state.usersByPage = newState
                state.isMutation.extra.uUserInfo = true
                state.isMutation.extra.action = 'lockUser'
                state.isMutation.extra.user = action.payload.newData
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(lock.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // ADD CASH
            .addCase(addCash.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(addCash.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.usersByPage.map((user) => {
                    if (user._id === action.payload.newData._id) {
                        return {
                            ...user,
                            credits_purchased: action.payload.newData.credits_purchased,
                        }
                    }
                    return user
                })
                state.usersByPage = newState
                state.isMutation.extra.uUserInfo = true
                state.isMutation.extra.action = 'addCash'
                state.isMutation.extra.amount = action.payload.totalCredits
                state.isMutation.extra.user = action.payload.newData
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(addCash.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // DELETE
            .addCase(del.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(del.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.success = true;
                state.isMutation.message = action.payload.message;
            })
            .addCase(del.rejected, (state, action) => {
                state.isMutation.loading = false;
            })
    },
});

export const { UPDATE_USER_FROM_SOCKET, SET_THEME, LOGOUT, SET_ADMIN_TOKEN, SET_USER_TOKEN, SET_CASH_USER, UPDATE_USER_INFO, UPDATE_CONFIGURATION, SET_TO_DEFAULT_RESPONSE } = userSlice.actions;

export default userSlice.reducer;

