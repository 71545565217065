// App.js
import { Pagination, PaginationItem, Stack, TextField, Link, Tooltip, IconButton, Button } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaArrowDown, FaArrowUp, FaBorderNone, FaClipboard, FaHistory, FaShippingFast, FaTrashAlt, FaUser } from 'react-icons/fa';
import { IoAlarm, IoBagCheck, IoBan, IoBuild, IoCheckbox, IoCloseSharp, IoDesktopSharp, IoEye, IoFingerPrint, IoLockClosed, IoLockOpen, IoLogoWhatsapp, IoPerson, IoReload, IoReloadCircleSharp, IoRocket, IoSave, IoSearch, IoThumbsDownSharp, IoTime } from 'react-icons/io5';
import ScreenHeader from '../../components/ScreenHeader';
import SpinnerData from '../../components/SpinnerData';
import { CalcularExpiracion, CalcularTiempoTranscurrido, currencyFormat, generateBackgroundColor, message, setUnixToTimeFormat } from '../../utils/Funciones';
import Swal from "sweetalert2";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import Wrapper from './Wrapper';
import moment from 'moment';
import { MdAdminPanelSettings, MdAlternateEmail, MdAvTimer, MdClose, MdDelete, MdDevices, MdMail, MdOutgoingMail, MdPhoneIphone, MdRotateLeft, MdRotateRight, MdTimer, MdTimerOff, MdVerified, MdWarning } from 'react-icons/md';
import Spinner from '../../components/Spinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useDispatch, useSelector } from 'react-redux';
import { SET_TO_DEFAULT_RESPONSE, deleteAll, getBypage } from '../../store/features/slices/historySlice';
import { uriSerialized } from '../../utils/uriSerialized';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { FaHandHoldingDroplet, FaHandHoldingMedical, FaRegUser } from 'react-icons/fa6';
import { FcVip } from 'react-icons/fc';

const History = ({ }) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(30);
  const {
    historiesByPage,
    contador,
    isMutation,
    totalFilter,
    total,
    totalPages
  } = useSelector((state) => state.historyReducer);

  useEffect(() => {
    if (isMutation.success) {
      message(isMutation.message, 'success', 5)
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
    const queryOptions = {
      page: currentPage,
      limit: limit,
      search: searchTerm.trim(),
    }
    const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
    dispatch(getBypage(query))
  }, [dispatch, searchTerm, currentPage, limit, isMutation.success]);

  useEffect(() => {
    if (isMutation.deleted) {
      message(isMutation.message, 'success', 5)
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
  }, [isMutation.deleted]);

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar Historial',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const changeHandleSearch = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  };

  const handleDeleteHistory = () => {
    alertaConfirmar('eliminar Historial', 'Esta acción eliminará todo el historial, deseas continuar?.', 'question', (res_alert) => {
      if (res_alert === true) {
        dispatch(deleteAll())
      }
    })
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  const onChangeSearchTermClear = e => {
    setSearchTerm("");
    document.getElementById("search").value = "";
  };

  const onCopy = (text) => {
    message(text, 'success', 2)
  }

  return (

    <Wrapper>
      {/* HEADER PAGES */}
      <div className="fixed ml-7 md:ml-0 pl-3 md:pl-3 lg:pl-3 h-8 top-2 md:top-11 z-30 flex items-center w-full md:w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
        Historial
        <div class="flex items-center justify-center px-3 ml-3 text-xl font-medium bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">
          {!contador.loading && contador.total}
        </div>
      </div>
      <div className="fixed mt-8 p-4 flex items-center text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] font-semibold bg-white dark:bg-brand-900 backdrop-blur-lg z-20 rounded-b-xl">
        <div className="!w-9/12">
          <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
            <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
              <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
            </InputLeftElement>
            <Input size='lg' autoFocus id="search" variant='flushed' onChange={debouncedChangeHandler} type="text" placeholder={`Buscar historial...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
            <InputRightElement className="!mt-[8px] !mr-1">
              <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchTerm.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>

      <div className="w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] mb-0 mt-[102px] pb-3 pt-5 md:pt-5 lg:pt-10 fixed z-20 bg-white/10 dark:bg-[#00000023] backdrop-blur-lg flex flex-col md:flex-col lg:flex-row items-center">
        <div className="order-1 md:order-1 lg:order-2 w-full md:w-full lg:w-6/12 flex items-center justify-center md:justify-center lg:justify-end text-brand-200 mb-6 md:mb-6 lg:mb-0">
          {
            !isMutation.loading ?
              <>
                <div className="hidden md:block">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="large"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                      className="bg-brand-200 dark:bg-brand-950"
                    />
                  </Stack>
                </div>

                <div className="block md:hidden">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="small"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                      className="bg-brand-200 dark:bg-brand-950"
                    />
                  </Stack>
                </div>
              </> : <Spinner />
          }
        </div>
        <div className="order-2 md:order-2 lg:order-1 w-full md:w-full lg:w-6/12">
          <Tooltip title="eliminar historial" arrow={true}>
            <Button onClick={handleDeleteHistory} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
              <FaTrashAlt className="w-5 h-5 text-white" />
            </Button>
          </Tooltip>
        </div>
      </div>

      <div className="relative w-full">
        {!isMutation.loading ? historiesByPage.length > 0 ?
          <div className="flex flex-wrap mt-[275px] md:mt-[290px] lg:mt-[260px]">
            {historiesByPage.map((histories) => {
              var categoryName = ""
              var categoryLogo = ""
              var isStreaming = false
              var isSMMServices = false

              if (histories.subcategory) {
                isStreaming = true
                categoryName = histories.subcategory.name
                categoryLogo = <LazyLoadImage
                  className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px] z-10 rounded-full flex inline-flex object-cover mr-2"
                  alt={`No image ${histories.subcategory.name}`}
                  effect="opacity"
                  src={`/images/logos/${histories.subcategory.logo}`}
                />
              }

              if (histories.smmservicio) {
                isSMMServices = true
                categoryName = histories.smmservicio?.name
                categoryLogo = <LazyLoadImage
                  className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px] z-10 rounded-full flex inline-flex object-cover mr-2"
                  alt={`No image ${histories.smmservicio?.name}`}
                  effect="opacity"
                  src={`/images/logos/${histories.plataform?.sublogo}`}
                />
              }

              if (isStreaming === false && isSMMServices === false) {
                if (histories.type === 'admin') {
                  categoryName = histories.type
                  categoryLogo = <MdAdminPanelSettings className='mr-2' size={20} />

                } else {
                  categoryName = histories.type
                  if (histories.type === 'VIP1' || histories.type === 'VIP2' || histories.type === 'VIP3') {
                    categoryLogo = <FcVip className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px] z-10"/>
                  } else {
                    categoryLogo = <LazyLoadImage
                      className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px] z-10 rounded-full flex inline-flex object-cover mr-2"
                      alt={`No image`}
                      effect="opacity"
                      src={`/images/paymethods/${histories.type}.png`}
                    />
                  }
                }
              }

              if (histories.type === "" && isStreaming === false) {
                categoryName = "Sin datos"
                categoryLogo = <MdClose className='text-red-500 mr-2' size={20} />
              }

              return (
                <div className={`relative p-4 w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-12 rounded-lg animation`} key={histories._id}>
                  <label className="absolute -top-8 left-0 p-2 flex items-center text-brand-800 dark:text-brand-200 text-base rounded-tl-lg rounded-tr-lg bg-white dark:bg-brand-900">
                    <span className="text-[12px]">{histories.transaction_id}</span>
                  </label>
                  <div className="grid grid-wrap md:w-full lg:w-4/12">
                    <div className="flex inline-flex items-center">
                      {
                        histories.user?.photo === 'unknowphoto.webp' ?
                          <div style={{ background: `${generateBackgroundColor(histories?.user?.name)}` }} className="uppercase relative max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] rounded-full flex items-center justify-center text-white text-2xl font-normal z-10">
                            {histories?.user?.name.substr(0, 1)}
                          </div>
                          :
                          <LazyLoadImage
                            className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] rounded-full object-cover"
                            alt={'No image'}
                            effect="opacity"
                            src={`/images/photos/${histories?.user?.photo}`}
                          />
                      }
                      <div className="grid gap-1 self-center">
                        <div className="ml-2 flex items-center text-sm truncate ..."><MdAlternateEmail size={16} className='mr-[5px]' />  {histories.user ? histories.user?.email : 'sin datos'}
                          {histories.user &&
                            <span className="ml-3">
                              <Tooltip title="copiar" arrow={true}>
                                <div>
                                  <CopyToClipboard text={histories.user?.email} onCopy={() => onCopy('correo copiado')}>
                                    <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer w-[35px]" />
                                  </CopyToClipboard>
                                </div>
                              </Tooltip>
                            </span>
                          }
                        </div>
                        <div className="ml-2 flex items-center text-xs truncate ..."><FaRegUser size={16} className='mr-[5px]' /> {histories.user ? histories.user?.name : 'sin datos'}</div>
                        <div className="ml-1 flex items-center text-xs mt-2 uppercase truncate ...">{categoryLogo}{categoryName}</div>
                        <div className="ml-2 text-xs mt-1">{setUnixToTimeFormat("created_at", histories.created_at)}</div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-full lg:w-[calc(66.66%-250px)] mt-5 md:mt-5 lg:mt-0">
                    <div className="w-full md:w-full lg:w-8/12 flex flex-wrap">
                      <div className="w-full flex items-center justify-center uppercase text-sm mb-2">Créditos</div>
                      <div className="w-4/12 grid">
                        <div className="flex items-center justify-center uppercase text-sm">antes</div>
                        <div className="flex items-center justify-center">
                          <div className="flex inline-flex items-center justify-center">
                            <div class="w-full text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">
                              <MdRotateLeft size={24} className="mr-2" />{currencyFormat(histories.old_credits)}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-4/12 grid">
                        <div className="flex items-center justify-center uppercase text-sm">monto</div>
                        <div className="flex items-center justify-center">
                          <div className="flex inline-flex items-center justify-center">
                            {(histories.new_credits - histories.old_credits) < 0 ?
                              <div class="w-full text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><FaArrowDown className='mr-1' /> {currencyFormat(histories.new_credits - histories.old_credits)}</div>
                              :
                              <div class="w-full text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><FaArrowUp className='mr-1' /> {currencyFormat(histories.new_credits - histories.old_credits)}</div>
                            }

                          </div>
                        </div>
                      </div>

                      <div className="w-4/12 grid">
                        <div className="flex items-center justify-center uppercase text-sm">despues</div>
                        <div className="flex items-center justify-center">
                          <div className="flex inline-flex items-center justify-center">
                            <div class="w-full text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2">
                              <MdRotateRight size={24} className="mr-2" />{currencyFormat(histories.new_credits)}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="w-full md:w-full lg:w-[250px] grid mt-5 md:mt-5 lg:mt-0">
                    <div className="flex items-center justify-center uppercase text-sm">accion</div>
                    <div className="flex items-center justify-center">
                      <div className="flex inline-flex items-center">
                        {
                          histories.action === 'New Streaming' &&
                          <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">Pedido streaming</div>
                        }

                        {
                          histories.action === 'New GiftCard' &&
                          <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">Pedido T. regalo</div>
                        }

                        {
                          histories.action === 'Add Recharge' &&
                          <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">Recarga créditos</div>
                        }

                        {
                          histories.action === 'Penalty Recharge' &&
                          <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">Penalizar recarga</div>
                        }

                        {
                          histories.action === 'Subscription VIP' &&
                          <div class="text-center text-yellow-950 dark:text-yellow-50 bg-gradient-to-r from-yellow-400 dark:from-yellow-500 rounded-l-lg flex items-center p-2">Subscripción VIP</div>
                        }

                        {
                          histories.action === 'Rejected Streaming' &&
                          <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">Pedido streaming rechazado</div>
                        }

                        {
                          histories.action === 'Rejected GiftCard' &&
                          <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">Pedido T. regalo rechazado</div>
                        }

                        {
                          histories.action === 'Renew Streaming' &&
                          <div class="text-center text-purple-950 dark:text-purple-50 bg-gradient-to-r from-purple-400 dark:from-purple-500 rounded-l-lg flex items-center p-2">Renovación streaming</div>
                        }

                        {
                          histories.action === 'New Social Order' &&
                          <div class="text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2">Pedido SMM servicios</div>
                        }

                        {
                          histories.action === 'Refunded Social' &&
                          <div class="text-center text-cyan-950 dark:text-cyan-50 bg-gradient-to-r from-cyan-400 dark:from-cyan-500 rounded-l-lg flex items-center p-2">Reembolso SMM servicios</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div> : <div className="alert-danger font-semibold mt-[275px] md:mt-[290px] lg:mt-[260px]">Sin resultados en la búsqueda! o no existe historial registrado</div> : <div className="mt-[275px] md:mt-[290px] lg:mt-[260px]"><SpinnerData>Cargando historial, por favor espere...</SpinnerData></div>}
      </div>
    </Wrapper>
  );
};

export default History;
